<template>
  <div class="cvs-info">
    <div class="title">{{ $t('__cvs') }}</div>
    <table class="detial-table">
      <tr>
        <th>{{ $t('__payment_no') }}</th>
        <td>{{ paymentNo }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'CVSInfo',
  props: {
    'payment-no': {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.cvs-info {
  width: calc(100% - 2em);
  padding: 1em;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: .28571429rem;
}
.title {
  font-size: 1.6em;
  font-weight: bold;
  margin-bottom: 0.4em;
}
.detial-table {
  th {
    vertical-align: top;
    padding-right: 1em;
    text-align: left;
  }
}
</style>