<template>
  <div class="payment-detial">
    <table class="detial-table">
      <tr>
        <th>{{ $t('__order_id') }}</th>
        <td>{{ orderId }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'PaymentDetial',
  props: {
    'order-id': {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.payment-detial{
  width: calc(100% - 2em);
  padding: 1em;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: .28571429rem;
}
.detial-table {
  th {
    vertical-align: top;
    padding-right: 1em;
  }
}
</style>