<template>
  <div class="barcode-info">
    <div class="title">{{ $t('__barcode') }}</div>
    <table class="detial-table">
      <tr>
        <th>{{ $t('__barcode_1') }}</th>
        <td><svg id="barcode1"/></td>
      </tr>
      <tr>
        <th>{{ $t('__barcode_2') }}</th>
        <td><svg id="barcode2"/></td>
      </tr>
      <tr>
        <th>{{ $t('__barcode_3') }}</th>
        <td><svg id="barcode3"/></td>
      </tr>
    </table>
  </div>
</template>

<script>
import JsBarcode from 'jsbarcode';

export default {
  name: 'BarcodeInfo',
  props: {
    'barcode-1': {
      type: String,
      required: true
    },
    'barcode-2': {
      type: String,
      required: true
    },
    'barcode-3': {
      type: String,
      required: true
    }
  },
  mounted() {
    this.renderBarcode("#barcode1", this.barcode1, 1.64);
    this.renderBarcode("#barcode2", this.barcode2);
    this.renderBarcode("#barcode3", this.barcode3, 1.06);
  },
  watch: {
    barcode1: {
      handler(val) {
        if (val) {
          this.renderBarcode("#barcode1", val, 1.64);
        }
      }
    },
    barcode2: {
      handler(val) {
        if (val) {
          this.renderBarcode("#barcode2", val);
        }
      }
    },
    barcode3: {
      handler(val) {
        if (val) {
          this.renderBarcode("#barcode3", val, 1.06);
        }
      }
    }
  },
  methods: {
    renderBarcode(id, val, width=1, height=60, fontSize=10) {
      JsBarcode(id, val, {
        format: 'CODE39',
        width,
        height,
        fontSize,
        background: '#ff000000',
      })
    }
  }
}
</script>

<style scoped lang="scss">
.barcode-info {
  width: calc(100% - 2em);
  padding: 1em;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: .28571429rem;
}
.title {
  font-size: 1.6em;
  font-weight: bold;
  margin-bottom: 0.4em;
}
.detial-table {
  th {
    vertical-align: top;
    padding-right: 1em;
    text-align: left;
  }
}
</style>