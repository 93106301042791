<template>
  <div class="transfer-info">
    <div class="title">{{ $t('__transfer') }}</div>
    <table class="detial-table">
      <tr>
        <th>{{ $t('__bank_code') }}</th>
        <td>{{ bankCode }}</td>
      </tr>
      <tr>
        <th>{{ $t('__account') }}</th>
        <td>{{ account }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TransferInfo',
  props: {
    'bank-code': {
      type: String,
      required: true
    },
    'account': {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.transfer-info {
  width: calc(100% - 2em);
  padding: 1em;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: .28571429rem;
}
.title {
  font-size: 1.6em;
  font-weight: bold;
  margin-bottom: 0.4em;
}
.detial-table {
  th {
    vertical-align: top;
    padding-right: 1em;
    text-align: left;
  }
}
</style>