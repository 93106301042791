export const state = {
  user: null
}
export const getters = {
  user(state) {
    return state.user
  },
  isTimeout(state) {
    if (state.user) {
      const authDate = state.user['auth_date']
      const now = Math.floor(new Date().getTime() / 1000)
      const time = now - authDate
      if (time > -30 && time <= 3600) { // 1 hour, offset -30 s
        return false
      }
    }
    return true
  },
  string(state) {
    if (state.user) {
      let s = ''
      for (const key of Object.keys(state.user)) {
        if (s.length > 0) s += ';'
        s += key + '=' + state.user[key]
      }
      return s
    }
    return ''
  }
}
export const mutations = {
  SET_USER(state, user) {
    state.user = user
  }
}
export const actions = {
  setUser({ commit }, user) {
    commit('SET_USER', user)
  }
}