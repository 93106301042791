<template>
  <div class="pay">
    <div v-if="serverType === 'sandbox'"
      class="sandbox-background">
      <span>sandbox</span>
    </div>
    <div class="container">
      <OrderDetial class="element"
        :order-id="orderID"/>
      <TransferInfo class="element" v-if="bankCode"
        :bank-code="bankCode"
        :account="account"/>
      <CVSInfo class="element" v-if="paymentNo"
        :payment-no="paymentNo"/>
      <BarcodeInfo class="element" v-if="barcode1"
        :barcode-1="barcode1"
        :barcode-2="barcode2"
        :barcode-3="barcode3"/>
      <div v-if="telegramIsTimeout" class="telegram element" ref="telegram"/>
    </div>
  </div>
</template>

<script>
import OrderDetial from '@/components/OrderDetial'
import TransferInfo from '@/components/TransferInfo'
import BarcodeInfo from '@/components/BarcodeInfo'
import CVSInfo from '@/components/CVSInfo'
import { mapGetters } from 'vuex'
import { telegramAuth, payAPI } from '@/plugins/service'

export default {
  name: 'Pay',
  components: {
    OrderDetial,
    TransferInfo,
    CVSInfo,
    BarcodeInfo
  },
  computed: {
    ...mapGetters({
      telegramUser: 'telegram/user',
      telegramIsTimeout: 'telegram/isTimeout',
      telegramString: 'telegram/string'
    })
  },
  data() {
    return {
      serverType: 'production',

      orderID: '',
      method: '',
      paymentData: null,
      targetURL: '',
      params: '',
      bankCode: '',
      account: '',
      paymentNo: '',
      barcode1: '',
      barcode2: '',
      barcode3: ''
    }
  },
  beforeCreate() {
    this.$store.dispatch('setIsLoading', true)
  },
  mounted() {
    this.orderID = this.$route.query['order_id']
    if (!this.orderID) {
      this.$router.replace({ name: 'NotFound' })
    }
    this.setupTelegram()
    this.$store.dispatch('setIsLoading', false)
  },
  methods: {
    setupTelegram() {
      const script = document.createElement('script')
      script.async = true
      script.src = 'https://telegram.org/js/telegram-widget.js?3'
      script.setAttribute('data-telegram-login', 'chiamarket_pay_bot')
      script.setAttribute('data-size', 'large')
      script.setAttribute('data-userpic', 'true')
      window.onTelegramAuth = this.onTelegramAuth
      script.setAttribute('data-onauth', 'window.onTelegramAuth(user)')
      script.setAttribute('data-request-access', 'write')
      this.$refs.telegram?.appendChild(script)
    },
    onTelegramAuth(user) {
      // console.log('onTelegramAuth', user)
      this.$store.dispatch('telegram/setUser', user)
      this.$store.dispatch('setIsLoading', true)
      return payAPI.getOrderPaymentData(telegramAuth(this.telegramString), this.orderID)
      .then(res => {
        this.$store.dispatch('setIsLoading', false)
        // console.log('payAPI.getOrderPaymentData()', res)
        this.orderID = res['order_id']
        this.method = res['method']
        this.paymentData = res['data']
        this.serverType = res['is_sandbox']? 'sandbox' : 'production'
        if (this.method === 'ecpay') {
          this.targetURL = this.paymentData['target_url']
          this.params = this.paymentData['params']
          this.bankCode = this.paymentData['bank_code']
          this.account = this.paymentData['account']
          this.paymentNo = this.paymentData['payment_no']
          this.barcode1 = this.paymentData['barcode_1']
          this.barcode2 = this.paymentData['barcode_2']
          this.barcode3 = this.paymentData['barcode_3']
          if (this.targetURL) {
            // post
            this.postForm(this.targetURL, this.params)
          }
        }
      })
      .catch(() => {
        // console.error("getOrderPaymentData error: ", err)
        this.$router.replace({ name: 'NotFound' })
      })
    },
    postForm(url, params) {
      const form = document.createElement('form');
      form.method = 'post';
      form.action = url;
      for (const key in params) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = params[key];
        form.appendChild(hiddenField);
      }
      document.body.appendChild(form);
      // console.log(form)
      form.submit();
    },
  }
}
</script>

<style scoped lang="scss">
.pay {
  width: 100%;
  position: relative;
}
.sandbox-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: rotate(-45deg);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #DDDDDD;
  font-size: 10em;
}
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  width: calc(100% - 2em);
  padding: 1em;
}
.element {
  margin-block: 0.5em;
  max-width: 400px;
}
</style>