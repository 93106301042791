import { createStore } from 'vuex'
import modules from '@/store/modules'

export default createStore({
  state: {
    isLoading: false
  },
  getters: {
    isLoading(state) {
      return state.isLoading
    }
  },
  mutations: {
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading
    }
  },
  actions: {
    setIsLoading({ commit }, isLoading) {
      commit('SET_IS_LOADING', isLoading)
    }
  },
  modules
})