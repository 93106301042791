import { createRouter, createWebHistory } from 'vue-router'
import NotFound from '@/views/NotFound.vue'
import Pay from '@/views/Pay.vue'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: 'Page Not Found'
    }
  },
  {
    path: '/pay',
    name: 'Pay',
    component: Pay,
    meta: {
      title: 'Pay'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  }
})

router.beforeResolve((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next();
});

export default router
