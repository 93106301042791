export class ServiceError extends Error {
    constructor (code, message) {
        super(message);
        Error.captureStackTrace(this, this.constructor);
        this.name = this.constructor.name;
        this.code = code;
    }

    toString () {
        return `[${this.name}] (${this.code}) ${this.message}\n${this.stack}`;
    }
}

/**
 * call service
 * @param {string} url
 * @param {Object} auth type: string, data: string
 * @param {Object} data
 * @returns {Promise<Object>} response json body
 */
export function callService(url, auth = null, data = {}) {
    const request = {
        method: 'POST',
        headers: {
            'Content-Type': 'text/plain'
        },
        body: JSON.stringify(data)
    }
    if (auth) {
        request.headers['Authorization'] = `${auth.type} ${auth.data}`
    }
    return fetch(
        url,
        request
    )
    .then(response => {
        return response.json();
    })
}

/**
 * telegram authorization
 * @param {string} data
 * @returns {Object} auth: { type: string, data: string }
 */
 export const telegramAuth = function(data) {
    return {
        type: 'Telegram',
        data: encodeURI(data)
    }
}

export * as payAPI from './services/payAPI/index.js'