import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)

import router from './router'
app.use(store)

import store from './store'
app.use(router)

import i18n from './i18n'
app.use(i18n)

import LoadingOverlay from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
app.component('loading-overlay', LoadingOverlay);

app.mount('#app')
