import { ServiceError, callService } from '../..'

const ServiceURL = 'https://api-pay-service-th4n4u7taa-an.a.run.app'

/**
 * call api
 * @param {string} api
 * @param {Object} auth type: string, data: string
 * @param {Object} data
 * @returns {Promise<any>}
 */
export const call = function(api, auth = null, data = {}) {
    return callService(`${ServiceURL}/api/${api}`, auth, data)
    .then(res => {
        return new Promise((resolve, reject) => {
            if (res.ok) resolve(res.result)
            else reject(new ServiceError(res.error_code, res.description))
        })
    })
}

/**
 * test
 * @param {Object} auth type: string, data: string
 * @returns {Promise<String>} "success"
 */
export const test = function(auth) {
    return call('test', auth)
}

/**
 * get order payment data
 * @param {Object} auth type: string, data: string
 * @param {string} orderID
 * @returns {Promise<String>} order_id, method, data
 */
export const getOrderPaymentData = function(auth, orderID) {
    return call('getOrderPaymentData', auth, {
        'order_id': orderID
    })
}
